import React from 'react';
import { Link } from 'gatsby';

const CallToActionSuccess = () => {
  return (
    <div className="flex items-end justify-between mt-12 sm:mt-32 mb-8 relative">
      <Link
        to="/"
        className="cta-left bg-blue-600 p-8 font-bold leading-tight text-white text-5xl sm:text-6xl uppercase"
      >
        <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
          Success
        </span>
      </Link>
      <Link
        className="bg-blue-600 font-bold leading-none mx-8 px-4 py-2 text-2xl text-white uppercase"
        to="/"
      >
        Home
      </Link>
    </div>
  );
};

export default CallToActionSuccess;
