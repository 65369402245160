import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import CallToActionSuccess from '../components/CallToAction/Success';
import Logo from '../images/assets/logo-light.svg';
import '../css/tailwind.css';

const SuccessPage = () => {
  const data = useStaticQuery(graphql`
    query SuccessPageQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div className="flex flex-col justify-center min-h-screen text-center">
      <SEO title="Success" />
      <Link to="/">
        <img
          className="w-3/4 mx-auto"
          src={Logo}
          alt={data.site.siteMetadata.title}
        />
      </Link>
      <h1 className="font-bold leading-none mt-12 text-blue-600 text-2xl sm:text-3xl uppercase">
        Form submitted successfully!
      </h1>
      <p className="font-light mt-4 text-blue-600 text-lg">
        Thank you for your message. We will get back to you as soon as we can.
      </p>
      <CallToActionSuccess />
    </div>
  );
};

export default SuccessPage;
